<template>
  <c-box w="100%">
    <c-flex
      margin-bottom="30px"
      w="100%"
      px="10px"
      align-items="center"
      grid-gap="10px"
    >
      <c-text
        font-size="24px"
        font-weight="700"
        color="primary.400"
      >
        Data Menu
      </c-text>
      <c-button
        v-if="isRemovable"
        variant="ghost"
        m="0"
        p="5px"
        min-w="auto"
        w="auto"
        h="auto"
        border-radius="25px"
        border="1px solid #343434"
        type="button"
        @click.stop="onRemove()"
      >
        <c-icon
          name="close"
          size="14px"
        />
      </c-button>
    </c-flex>
    <c-flex>
      <c-form-control
        is-required
        :is-invalid="$v.dose.$invalid"
        margin-bottom="30px"
        w="50%"
        px="10px"
      >
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Jumlah Takaran
        </c-form-label>
        <c-input-group size="md">
          <c-input
            v-model.number="dose"
            type="number"
            placeholder="Masukkan Jumlah Takaran"
            height="62px"
          />
        </c-input-group>
        <c-form-helper-text
          v-if="!$v.dose.required"
          color="red.500"
          font-size="12px"
        >
          Jumlah Takaran tidak boleh kosong
        </c-form-helper-text>
      </c-form-control>
      <c-form-control
        is-required
        :is-invalid="$v.doseUnit.$invalid"
        margin-bottom="30px"
        w="50%"
        px="10px"
      >
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Satuan Takaran
        </c-form-label>
        <c-input-group size="md">
          <c-input
            v-model="doseUnit"
            type="text"
            placeholder="Masukkan Satuan Takaran"
            height="62px"
          />
        </c-input-group>
        <c-form-helper-text
          v-if="!$v.doseUnit.required"
          color="red.500"
          font-size="12px"
        >
          Satuan Takaran tidak boleh kosong
        </c-form-helper-text>
      </c-form-control>
    </c-flex>
    <c-form-control
      is-required
      :is-invalid="$v.foodIngredient.$invalid"
      margin-bottom="30px"
      w="100%"
      px="10px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Nama Makanan
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model="foodIngredient"
          type="text"
          placeholder="Masukkan Nama Makanan"
          height="62px"
        />
      </c-input-group>
      <c-form-helper-text
        v-if="!$v.foodIngredient.required"
        color="red.500"
        font-size="12px"
      >
        Nama Makanan tidak boleh kosong
      </c-form-helper-text>
    </c-form-control>
    <c-form-control
      is-required
      :is-invalid="$v.mealGlossaryId.$invalid"
      :class="{invalid: $v.mealGlossaryId.$invalid}"
      margin-bottom="30px"
      w="100%"
      px="10px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Glossary Makanan
      </c-form-label>
      <autocomplete
        v-chakra
        placeholder="Pilih glossary makanan"
        :search="onSearch"
        :get-result-value="(it) => it.ingredientName"
        :default-value="ingredientName_"
        w="100%"
        auto-select
        @submit="onSubmit($event)"
      >
        <template
          #default="{
            rootProps,
            inputProps,
            inputListeners,
            resultListProps,
            resultListListeners,
            results,
            resultProps,
          }"
        >
          <c-flex v-bind="rootProps">
            <input
              v-chakra="{
                bg: 'white !important',
                px: '20px !important',
                border: '1px solid red.500 !important',
              }"
              v-bind="inputProps"
              height="62px"
              v-on="inputListeners"
            >
            <ul
              v-chakra="{
                zIndex: '1000 !important',
              }"
              v-bind="resultListProps"
              v-on="resultListListeners"
            >
              <li
                v-for="(result, index) in results"
                :key="resultProps[index].id"
                v-bind="resultProps[index]"
                v-chakra="{
                  bg: 'white !important',
                  p: 0,
                }"
                d="flex"
                align-items="center"
                gridGap="10px"
                :_hover="{ bg: '#fafafa !important' }"
              >
                <c-image
                  h="64px"
                  w="64px"
                  object-fit="cover"
                  :src="result.photoUrl"
                />
                <c-text flex="1">
                  {{ result.ingredientName }}
                </c-text>
              </li>
            </ul>
          </c-flex>
        </template>
      </autocomplete>
      <c-form-helper-text
        v-if="!$v.mealGlossaryId.required"
        color="red.500"
        font-size="12px"
      >
        Glossary Makanan tidak boleh kosong
      </c-form-helper-text>
    </c-form-control>
  </c-box>
</template>

<script>
import _ from 'lodash'
import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'
import equal from 'fast-deep-equal'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'FormDataMenu',
  components: {
    Autocomplete,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    isRemovable: {
      type: Boolean,
      default: false,
    },
    glossaries: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dose: null,
      doseUnit: null,
      foodIngredient: null,
      mealGlossaryId: null,
      mealIngredientNameGlossary: null,
    }
  },
  computed: {
    ingredientName_() {
      return this.value?.mealIngredientNameGlossary?.ingredientName
    },
    foodIngredientText() {
      return this.value.mealIngredientNameGlossary?.foodIngredient
    },
    values_() {
      return {
        id: this.value.id,
        dose: this.dose,
        doseUnit: this.doseUnit,
        foodIngredient: this.foodIngredient,
        mealGlossaryId: this.mealGlossaryId,
        mealIngredientNameGlossary: this.mealIngredientNameGlossary,
      }
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val, old) {
        if (val == null) return
        if (equal(val, old)) return

        this.setIfNull('dose', val.dose)
        this.setIfNull('doseUnit', val.doseUnit)
        this.setIfNull('foodIngredient', val.foodIngredient)
        this.setIfNull('mealIngredientNameGlossary', val.mealIngredientNameGlossary)

        if (this.mealGlossaryId == null && this.mealIngredientNameGlossary != null) {
          this.mealGlossaryId = this.mealIngredientNameGlossary.id
        }
      },
    },
    values_: _.debounce(function(val, old) {
      if (equal(val, old)) return

      this.$emit('input', val)
    }, 500),
  },
  async mounted() {
    await this.$store.dispatch('glossary/list')
  },
  methods: {
    setIfNull(key, val) {
      if (this[key] == null) {
        this[key] = val
      }
    },
    logDiff(tag, diffKeys, _new, _old) {
      for (let key of diffKeys) {
        console.log(`[${tag}] from.${key}(${_old?.[key]}) to.${key}(${_new?.[key]})`)
      }
    },
    getObjectDiff(obj1 = {}, obj2 = {}) {
      const diff = Object.keys(obj1).reduce((result, key) => {
        // eslint-disable-next-line no-prototype-builtins
        if (!obj2.hasOwnProperty(key)) {
          result.push(key)
        } else if (_.isEqual(obj1[key], obj2[key])) {
          const resultKeyIndex = result.indexOf(key)
          result.splice(resultKeyIndex, 1)
        }
        return result
      }, Object.keys(obj2))

      return diff
    },
    onSubmit($event) {
      this.mealGlossaryId = $event.id
    },
    async onSearch(search) {
      if (search == null || search.length <= 0) {
        return this.glossaries
      }
      return this.$store.dispatch('glossary/list', { q: search }).then(it => it.result)

      // return this.glossaries.filter(
      //   (it) =>
      //     it.foodIngredient.toLowerCase().indexOf(search.toLowerCase()) > -1
      // );
    },
    onRemove() {
      this.$emit('input', {
        ...this.values_,
        status: 'deleted',
      })
    },
  },
  validations: {
    dose: { required },
    doseUnit: { required },
    foodIngredient: { required },
    mealGlossaryId: { required },
  },
}
</script>

<style>
.invalid .autocomplete-input {
  border-color: #e66673;
  box-shadow: 0 0 0 1px #e66673;
}
</style>
