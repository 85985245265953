<template>
  <c-flex
    flex="1"
    flex-dir="column"
    overflow="auto"
    pb="30px"
  >
    <c-box
      px="40px"
      py="40px"
    >
      <c-flex justify-content="space-between">
        <c-heading
          color="black.900"
          font-size="24px"
          font-weight="700"
          margin-bottom="40px"
        >
          Rekomendasi Menu
        </c-heading>
        <c-button
          background-color="primary.400"
          font-size="16px"
          font-weight="700"
          font-family="Roboto"
          px="20px"
          border-radius="100px"
          color="white"
          :is-disabled="isInvalid"
          @click="simpan()"
        >
          <c-image
            :src="require('@/assets/save-icon.svg')"
            alt="image"
            margin-right="8px"
          />
          <c-text>Simpan</c-text>
        </c-button>
      </c-flex>
      <c-flex
        mt="15px"
        overflow-x="auto"
      >
        <badge-week
          v-for="(_, index) in 10"
          :key="index"
          v-chakra
          :selected="index + 1 === selectedWeek"
          :week="index + 1"
          :dotted="!daysWithData.includes(index + 1)"
          :ml="index !== 0 ? '10px' : ''"
          @click="selectedWeek = index + 1"
        />
      </c-flex>
      <c-flex
        v-chakra="{
          div: {
            width: '100%',
            maxWidth: '156px',
            marginRight: '12px',
            textAlign: 'center',
            ':last-child': {
              marginRight: '0',
            },
            p: {
              color: 'lightGray.900',
              fontWeight: '700',
              marginBottom: '10px',
            },
            '.step': {
              w: '100%',
              h: '8px',
              maxWidth: '156px',
              borderRadius: '20px',
              backgroundColor: 'superLightGray.900',
            },
            '&.done': {
              p: {
                color: 'primary.400',
              },
              '.step': {
                backgroundColor: 'primary.400',
              },
            },
            '&.selected': {
              p: { color: 'gray.700' },
              '.step': { bg: 'gray.700' },
            },
          },
        }"
        py="30px"
        w="100%"
        justify-content="space-evenly"
        align-items="flex-end"
      >
        <c-box
          v-for="category in categories"
          :key="category"
          :class="selectedCategory === category ? 'selected' : ''"
        >
          <c-text>{{ category | categoryTitle }}</c-text>
          <c-box class="step" />
        </c-box>
      </c-flex>
    </c-box>

    <template v-for="category in categories">
      <FormEditMenuRekomendasi
        v-if="category === selectedCategory"
        ref="formEdit"
        :key="category"
        :title="category | categoryTitle"
        :selected-day="selectedWeek"
        :category-data="selectedCategoryData"
        :time-start="selectedCategoryData.timeStart"
        :time-end="selectedCategoryData.timeEnd"
        :calories-needed="selectedCategoryData.caloriesNeed"
        :total-calories="selectedCategoryData.totalCalories"
        :glossaries="glossaries"
        :value="sortedSelectedMeals"
        @input="
          onInputMeals({
            category: category,
            programId: programId,
            mealPlansId: mealPlansId,
            clientId: clientId,
            day: selectedWeek,
            meal: $event,
          })
        "
        @next="next()"
        @prev="prev()"
        @add="onAddMeal()"
      />
    </template>
  </c-flex>
</template>

<script>
import BadgeWeek from './badge-week.vue'
import FormEditMenuRekomendasi from '@/views/meal-plan/forms/form-edit-menu-rekomendasi'
import getMenuCategory from '@/utils/get-menu-category'
import { mapActions, mapState } from 'vuex'
import _ from 'lodash'
import equal from 'fast-deep-equal'
import { sortMealCategory } from '@/utils/sort-meal-category'

export default {
  name: 'EditMenuRecommendation',
  components: { FormEditMenuRekomendasi, BadgeWeek },
  filters: {
    categoryTitle(val) {
      return getMenuCategory(val)
    },
  },
  data() {
    return {
      programId: null,
      selectedWeek: 1,
      selectedCategory: 'breakfast',
      data: [],
      meals: [],
      //---
      isInvalid: false,
    }
  },
  computed: {
    ...mapState({
      glossaries: (s) => s.glossary.data,
      profileGizi: (s) => s.mealPlan.profileGizi,
      mealPlan: (s) => s.mealPlan.rencanaMakan,
    }),
    mealPlansId() {
      return this.selectedCategoryData.id
    },
    categories() {
      let categories = this.mealPlan.flatMap((it) => it.category)
      return _.uniq(categories).sort(sortMealCategory)
    },
    selectedCategoryData() {
      return this.mealPlan?.find((it) => it.category === this.selectedCategory)
    },
    selectedMeals() {
      let items = this.meals
        .filter((it) => it.programId === this.programId)
        .filter(
          (it) =>
            it.day === this.selectedWeek && it.mealPlansId === this.mealPlansId,
        )
        .filter((it) => it.status !== 'deleted')

      return items
    },
    caloriesNeeded() {
      return this.selectedCategoryData.caloriesNeed ?? 20
    },
    totalCalories() {
      return this.caloriesNeeded * 10
    },
    valueToCreate() {
      let data = this.meals
        .filter(
          (it) =>
            it.isNew === true && (it.status == null || it.status !== 'deleted'),
        )
        .filter(
          (it) =>
            it.dose != null &&
            it.dose !== '' &&
            it.doseUnit != null &&
            it.doseUnit !== '' &&
            it.foodIngredient != null &&
            it.foodIngredient !== '' &&
            it.mealGlossaryId != null &&
            it.mealGlossaryId !== '',
        )
        .map((it) =>
          _.pick(it, [
            'id',
            'programId',
            'mealPlansId',
            'mealGlossaryId',
            'day',
            'dose',
            'doseUnit',
            'foodIngredient',
            'status',
            'sequence',
          ]),
        )
      return data
    },
    valueToUpdate() {
      let values = this.meals
        .filter((it) => it.isNew == null || it.isNew === false)
        .filter(
          (it) =>
            it.dose != null &&
            it.dose !== '' &&
            it.doseUnit != null &&
            it.doseUnit !== '' &&
            it.foodIngredient != null &&
            it.foodIngredient !== '' &&
            it.mealGlossaryId != null &&
            it.mealGlossaryId !== '',
        )
        .map((it) =>
          _.pick(it, [
            'id',
            'programId',
            'mealPlansId',
            'mealGlossaryId',
            'day',
            'dose',
            'doseUnit',
            'foodIngredient',
            'status',
            'sequence',
          ]),
        )
      return values
    },
    listingData() {
      return [this.programId, this.selectedWeek, this.clientId]
    },
    initialData() {
      return [this.mealPlan, this.data ?? [], this.glossaries]
    },
    daysWithData() {
      return _.uniq(this.meals.map((it) => it.day))
    },
    hasEmptyDays() {
      let days = Array.from({ length: 10 }, (_, i) => i + 1)
      return !days.every((it) => this.daysWithData.includes(it))
    },
    draftName() {
      return `rekomendasi-menu--${this.programId}`
    },
    sortedSelectedMeals() {
      return this.selectedMeals.slice().sort((a, b) => a.sequence - b.sequence)
    },
    sortedMeals() {
      return this.meals.slice().sort((a, b) => a.sequence - b.sequence)
    },
  },
  watch: {
    initialData(val, old) {
      if (equal(val, old)) return
      let [mealPlans, data, glossaries] = val
      if (mealPlans == null || data == null || glossaries == null) return

      let meals = []

      for (let item of data) {
        let mealPlan = mealPlans?.find((it) => it.id === item.mealPlansId)
        for (let meal of item.meals ?? []) {
          let mealItem = {
            ...meal,
            day: item.day,
            clientId: item.clientId,
            programId: item.programId ?? this.programId,
            mealPlansId: item.mealPlansId,
            category: mealPlan?.category,
            mealsRecommendationId: item.mealsRecommendationId,
            mealGlossaryId: meal.mealIngredientNameGlossary?.id,
            mealPlan,
          }

          let index = meals.findIndex((it) => equal(it, mealItem))
          if (index > -1) {
            this.$set(meals, index, mealItem)
          } else {
            meals.push(mealItem)
          }
        }
      }

      // this.meals = meals;
      for (let meal of meals) {
        let index = this.meals.findIndex((it) => it.id === meal.id)
        if (index > -1) {
          this.$set(this.meals, index, meal)
        } else {
          this.meals.push(meal)
        }
      }
    },
    listingData: {
      immediate: true,
      async handler(value, oldValue) {
        if (equal(value, oldValue)) return
        if (value.some((it) => it == null)) return

        this.selectedCategory = 'breakfast'

        let [programId, _, clientId] = value

        let listRekomendasiMenu = Array.from(
          { length: 10 },
          (_, day) => day + 1,
        ).map((day) => this.listRekomendasiMenu({ clientId, programId, day }))
        this.data = await Promise.all(listRekomendasiMenu).then((it) =>
          it.flatMap((it) => it),
        )
      },
    },
  },
  updated() {
    let isInvalid = this.$refs.formEdit
      ?.flatMap((it) => it.$refs.formDataMenu)
      ?.some((it) => it?.$v.$invalid === true)
    this.isInvalid = isInvalid || this.hasEmptyDays
  },
  async mounted() {
    if (this.isRoleNutritionist) {
      this.programId = await this.$store
        .dispatch('mealPlan/getLatestProgramForNutri', this.clientId)
        .then((it) => it.id)
    }
    await Promise.all([
      this.$store.dispatch('glossary/list'),
      this.$store.dispatch('mealPlan/list', {
        clientId: this.clientId,
        programId: this.programId,
      }),
      this.$store.dispatch('mealPlan/getProfileGizi', this.clientId),
    ])
  },
  methods: {
    ...mapActions({
      listRekomendasiMenu: 'mealPlan/listRekomendasiMenu',
    }),
    onInputMeals({ day, category, mealPlansId, programId, clientId, meal }) {
      if (meal.id) {
        let index = this.meals.findIndex((it) => it.id === meal.id)
        this.$set(this.meals, index, {
          ...this.meals[index],
          ...meal,
        })
      } else {
        this.meals.push({
          ...meal,
          day,
          category,
          mealPlansId,
          programId,
          clientId,
          // ----
          id: `new_data--${Date.now()}`,
          isNew: true,
        })
      }
    },
    simpan() {
      if (this.isInvalid) {
        return
      }

      let promises = []
      if (this.valueToUpdate.length > 0) {
        promises.push(
          this.axios.put(
            '/v1/nutritionist/meals-recommendation',
            this.valueToUpdate,
          ),
        )
      }
      if (this.valueToCreate.length > 0) {
        promises.push(
          this.axios.post(
            `/v1/nutritionist/meals-recommendation/${this.clientId}`,
            this.valueToCreate,
          ),
        )
      }

      return Promise.allSettled(promises).then(() => {
        this.$router.push({
          name: 'nutri.rekomendasi-menu',
          params: this.$route.params,
        })
      })
    },
    onAddMeal() {
      let mealPlan = this.mealPlan.find((it) => it.id === this.mealPlansId)
      let nextSequence = (_.last(this.sortedMeals)?.sequence ?? 0) + 1 ?? 1

      this.meals.push({
        day: this.selectedWeek,
        category: this.selectedCategory,
        mealPlansId: this.mealPlansId,
        programId: this.programId,
        clientId: this.clientId,
        mealPlan,

        dose: null,
        doseUnit: null,
        foodIngredient: null,
        mealGlossaryId: null,
        mealIngredientNameGlossary: {},

        // ----
        id: `new_data--${Date.now()}`,
        isNew: true,
        sequence: nextSequence,
      })
    },
    next() {
      let index = this.categories.findIndex(
        (it) => it === this.selectedCategory,
      )

      if (index >= this.categories.length - 1) {
        if (this.selectedWeek < 10) {
          this.selectedWeek = this.selectedWeek + 1
        }
        return
      }

      this.selectedCategory = this.categories[index + 1]
    },
    prev() {
      let index = this.categories.findIndex(
        (it) => it === this.selectedCategory,
      )
      if (index <= 0) {
        if (this.selectedWeek > 1) {
          this.selectedWeek = this.selectedWeek - 1
        }
        return
      }

      this.selectedCategory = this.categories[index - 1]
    },
    // isEqualMeal(meal1, meal2) {},
  },
}
</script>
