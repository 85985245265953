<template>
  <c-box px="20px">
    <c-box margin-bottom="30px">
      <c-text
        font-size="16px"
        font-weight="700"
        font-family="Roboto"
        color="primary.400"
      >
        Rekomendasi Menu
      </c-text>
      <c-text
        font-size="24px"
        font-weight="700"
        color="black.900"
      >
        {{ title }}
      </c-text>
    </c-box>
    <c-flex
      v-chakra="{
        '.disabled': {
          borderColor: '#C4C4C4',
          backgroundColor: 'superLightGray.900',
          color: 'black.900',
          cursor: 'not-allowed',
          pointerEvents: 'none',
          button: {
            borderColor: '#C4C4C4',
          },
        },
      }"
      flex-wrap="wrap"
      mx="-10px"
    >
      <c-form-control
        margin-bottom="30px"
        w="50%"
        px="10px"
      >
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Jam Mulai Makan
        </c-form-label>
        <c-input-group size="md">
          <c-input
            id="time1"
            type="text"
            height="62px"
            class="disabled"
            :value="timeStart"
          />
          <c-input-right-element
            width="4.5rem"
            mt="10px"
          >
            <c-image
              :src="require('@/assets/icon-jam-grey.svg')"
              alt="image"
            />
          </c-input-right-element>
        </c-input-group>
      </c-form-control>
      <c-form-control
        margin-bottom="30px"
        w="50%"
        px="10px"
      >
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Jam Akhir Makan
        </c-form-label>
        <c-input-group size="md">
          <c-input
            id="time2"
            type="text"
            :value="timeEnd"
            height="62px"
            class="disabled"
          />
          <c-input-right-element
            width="4.5rem"
            mt="10px"
          >
            <c-image
              :src="require('@/assets/icon-jam-grey.svg')"
              alt="image"
            />
          </c-input-right-element>
        </c-input-group>
      </c-form-control>
      <c-form-control
        margin-bottom="30px"
        w="100%"
        px="10px"
      >
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Kebutuhan Jumlah Kalori
        </c-form-label>
        <c-input-group size="md">
          <c-select
            class="disabled"
            is-disabled
            :placeholder="`${caloriesNeeded}%`"
            bg="#f2f2f2"
            height="62px"
          />
        </c-input-group>
      </c-form-control>
      <c-form-control
        margin-bottom="30px"
        w="100%"
        px="10px"
      >
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Kkal - Jumlah Kalori
        </c-form-label>
        <c-input-group size="md">
          <c-input
            type="text"
            :value="percentageTotalCalories"
            height="62px"
            class="disabled"
          />
        </c-input-group>
      </c-form-control>

      <template v-for="(meal, index) in value">
        <FormDataMenu
          ref="formDataMenu"
          :key="index"
          :is-removable="true"
          :glossaries="glossaries"
          :value="meal"
          @input="$emit('input', $event)"
        />
      </template>
      <c-form-control
        w="100%"
        margin-bottom="30px"
        d="flex"
      >
        <c-button
          bg="white"
          font-size="18px"
          font-weight="500"
          color="primary.400"
          min-height="61px"
          border-radius="100px"
          left-icon="add"
          border="1px solid #008C81"
          w="95%"
          mx="auto"
          text-align="center"
          :is-disabled="isInvalid"
          @click="$emit('add')"
        >
          Tambah Data Menu Lain
        </c-button>
      </c-form-control>

      <c-flex
        w="100%"
        justify-content="space-between"
        align-items="center"
        margin-top="30px"
      >
        <c-button
          left-icon="chevron-left"
          background-color="primary.400"
          color="white"
          border-radius="100px"
          variant="solid"
          px="30px"
          @click="$emit('prev')"
        >
          Kembali
        </c-button>
        <c-button
          right-icon="chevron-right"
          background-color="primary.400"
          color="white"
          border-radius="100px"
          variant="solid"
          px="30px"
          :is-disabled="isInvalid"
          @click="$emit('next')"
        >
          Selanjutnya
        </c-button>
      </c-flex>
    </c-flex>
  </c-box>
</template>

<script>
import FormDataMenu from '@/views/meal-plan/forms/form-data-menu.vue'

export default {
  name: 'FormEditMenuRekomendasi',
  components: { FormDataMenu },
  props: {
    glossaries: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    timeStart: {
      type: String,
      default: '',
    },
    timeEnd: {
      type: String,
      default: '',
    },
    caloriesNeeded: {
      type: Number,
      default: 0,
    },
    totalCalories: {
      type: Number,
      default: 0,
    },
    selectedDay: {
      type: String,
      default: '',
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isInvalid: false,
    }
  },
  computed: {
    percentageTotalCalories() {
      return this.totalCalories / 100
    },
  },
  updated() {
    this.isInvalid = this.$refs.formDataMenu?.some(
      (it) => it?.$v.$invalid === true,
    )
  },
}
</script>
