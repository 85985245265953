var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "px": "20px"
    }
  }, [_c('c-box', {
    attrs: {
      "margin-bottom": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "16px",
      "font-weight": "700",
      "font-family": "Roboto",
      "color": "primary.400"
    }
  }, [_vm._v(" Rekomendasi Menu ")]), _c('c-text', {
    attrs: {
      "font-size": "24px",
      "font-weight": "700",
      "color": "black.900"
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")])], 1), _c('c-flex', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '.disabled': {
          borderColor: '#C4C4C4',
          backgroundColor: 'superLightGray.900',
          color: 'black.900',
          cursor: 'not-allowed',
          pointerEvents: 'none',
          button: {
            borderColor: '#C4C4C4'
          }
        }
      },
      expression: "{\n      '.disabled': {\n        borderColor: '#C4C4C4',\n        backgroundColor: 'superLightGray.900',\n        color: 'black.900',\n        cursor: 'not-allowed',\n        pointerEvents: 'none',\n        button: {\n          borderColor: '#C4C4C4',\n        },\n      },\n    }"
    }],
    attrs: {
      "flex-wrap": "wrap",
      "mx": "-10px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "margin-bottom": "30px",
      "w": "50%",
      "px": "10px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Jam Mulai Makan ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    staticClass: "disabled",
    attrs: {
      "id": "time1",
      "type": "text",
      "height": "62px",
      "value": _vm.timeStart
    }
  }), _c('c-input-right-element', {
    attrs: {
      "width": "4.5rem",
      "mt": "10px"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-jam-grey.svg'),
      "alt": "image"
    }
  })], 1)], 1)], 1), _c('c-form-control', {
    attrs: {
      "margin-bottom": "30px",
      "w": "50%",
      "px": "10px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Jam Akhir Makan ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    staticClass: "disabled",
    attrs: {
      "id": "time2",
      "type": "text",
      "value": _vm.timeEnd,
      "height": "62px"
    }
  }), _c('c-input-right-element', {
    attrs: {
      "width": "4.5rem",
      "mt": "10px"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-jam-grey.svg'),
      "alt": "image"
    }
  })], 1)], 1)], 1), _c('c-form-control', {
    attrs: {
      "margin-bottom": "30px",
      "w": "100%",
      "px": "10px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Kebutuhan Jumlah Kalori ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    staticClass: "disabled",
    attrs: {
      "is-disabled": "",
      "placeholder": "".concat(_vm.caloriesNeeded, "%"),
      "bg": "#f2f2f2",
      "height": "62px"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "margin-bottom": "30px",
      "w": "100%",
      "px": "10px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Kkal - Jumlah Kalori ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    staticClass: "disabled",
    attrs: {
      "type": "text",
      "value": _vm.percentageTotalCalories,
      "height": "62px"
    }
  })], 1)], 1), _vm._l(_vm.value, function (meal, index) {
    return [_c('FormDataMenu', {
      key: index,
      ref: "formDataMenu",
      refInFor: true,
      attrs: {
        "is-removable": true,
        "glossaries": _vm.glossaries,
        "value": meal
      },
      on: {
        "input": function input($event) {
          return _vm.$emit('input', $event);
        }
      }
    })];
  }), _c('c-form-control', {
    attrs: {
      "w": "100%",
      "margin-bottom": "30px",
      "d": "flex"
    }
  }, [_c('c-button', {
    attrs: {
      "bg": "white",
      "font-size": "18px",
      "font-weight": "500",
      "color": "primary.400",
      "min-height": "61px",
      "border-radius": "100px",
      "left-icon": "add",
      "border": "1px solid #008C81",
      "w": "95%",
      "mx": "auto",
      "text-align": "center",
      "is-disabled": _vm.isInvalid
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('add');
      }
    }
  }, [_vm._v(" Tambah Data Menu Lain ")])], 1), _c('c-flex', {
    attrs: {
      "w": "100%",
      "justify-content": "space-between",
      "align-items": "center",
      "margin-top": "30px"
    }
  }, [_c('c-button', {
    attrs: {
      "left-icon": "chevron-left",
      "background-color": "primary.400",
      "color": "white",
      "border-radius": "100px",
      "variant": "solid",
      "px": "30px"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('prev');
      }
    }
  }, [_vm._v(" Kembali ")]), _c('c-button', {
    attrs: {
      "right-icon": "chevron-right",
      "background-color": "primary.400",
      "color": "white",
      "border-radius": "100px",
      "variant": "solid",
      "px": "30px",
      "is-disabled": _vm.isInvalid
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('next');
      }
    }
  }, [_vm._v(" Selanjutnya ")])], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }