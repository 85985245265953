var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "100%"
    }
  }, [_c('c-flex', {
    attrs: {
      "margin-bottom": "30px",
      "w": "100%",
      "px": "10px",
      "align-items": "center",
      "grid-gap": "10px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "24px",
      "font-weight": "700",
      "color": "primary.400"
    }
  }, [_vm._v(" Data Menu ")]), _vm.isRemovable ? _c('c-button', {
    attrs: {
      "variant": "ghost",
      "m": "0",
      "p": "5px",
      "min-w": "auto",
      "w": "auto",
      "h": "auto",
      "border-radius": "25px",
      "border": "1px solid #343434",
      "type": "button"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.onRemove();
      }
    }
  }, [_c('c-icon', {
    attrs: {
      "name": "close",
      "size": "14px"
    }
  })], 1) : _vm._e()], 1), _c('c-flex', [_c('c-form-control', {
    attrs: {
      "is-required": "",
      "is-invalid": _vm.$v.dose.$invalid,
      "margin-bottom": "30px",
      "w": "50%",
      "px": "10px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Jumlah Takaran ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "placeholder": "Masukkan Jumlah Takaran",
      "height": "62px"
    },
    model: {
      value: _vm.dose,
      callback: function callback($$v) {
        _vm.dose = _vm._n($$v);
      },
      expression: "dose"
    }
  })], 1), !_vm.$v.dose.required ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500",
      "font-size": "12px"
    }
  }, [_vm._v(" Jumlah Takaran tidak boleh kosong ")]) : _vm._e()], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "is-invalid": _vm.$v.doseUnit.$invalid,
      "margin-bottom": "30px",
      "w": "50%",
      "px": "10px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Satuan Takaran ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "placeholder": "Masukkan Satuan Takaran",
      "height": "62px"
    },
    model: {
      value: _vm.doseUnit,
      callback: function callback($$v) {
        _vm.doseUnit = $$v;
      },
      expression: "doseUnit"
    }
  })], 1), !_vm.$v.doseUnit.required ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500",
      "font-size": "12px"
    }
  }, [_vm._v(" Satuan Takaran tidak boleh kosong ")]) : _vm._e()], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "is-invalid": _vm.$v.foodIngredient.$invalid,
      "margin-bottom": "30px",
      "w": "100%",
      "px": "10px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Nama Makanan ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "placeholder": "Masukkan Nama Makanan",
      "height": "62px"
    },
    model: {
      value: _vm.foodIngredient,
      callback: function callback($$v) {
        _vm.foodIngredient = $$v;
      },
      expression: "foodIngredient"
    }
  })], 1), !_vm.$v.foodIngredient.required ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500",
      "font-size": "12px"
    }
  }, [_vm._v(" Nama Makanan tidak boleh kosong ")]) : _vm._e()], 1), _c('c-form-control', {
    class: {
      invalid: _vm.$v.mealGlossaryId.$invalid
    },
    attrs: {
      "is-required": "",
      "is-invalid": _vm.$v.mealGlossaryId.$invalid,
      "margin-bottom": "30px",
      "w": "100%",
      "px": "10px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Glossary Makanan ")]), _c('autocomplete', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "placeholder": "Pilih glossary makanan",
      "search": _vm.onSearch,
      "get-result-value": function getResultValue(it) {
        return it.ingredientName;
      },
      "default-value": _vm.ingredientName_,
      "w": "100%",
      "auto-select": ""
    },
    on: {
      "submit": function submit($event) {
        return _vm.onSubmit($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var rootProps = _ref.rootProps,
            inputProps = _ref.inputProps,
            inputListeners = _ref.inputListeners,
            resultListProps = _ref.resultListProps,
            resultListListeners = _ref.resultListListeners,
            results = _ref.results,
            resultProps = _ref.resultProps;
        return [_c('c-flex', _vm._b({}, 'c-flex', rootProps, false), [_c('input', _vm._g(_vm._b({
          directives: [{
            name: "chakra",
            rawName: "v-chakra",
            value: {
              bg: 'white !important',
              px: '20px !important',
              border: '1px solid red.500 !important'
            },
            expression: "{\n              bg: 'white !important',\n              px: '20px !important',\n              border: '1px solid red.500 !important',\n            }"
          }],
          attrs: {
            "height": "62px"
          }
        }, 'input', inputProps, false), inputListeners)), _c('ul', _vm._g(_vm._b({
          directives: [{
            name: "chakra",
            rawName: "v-chakra",
            value: {
              zIndex: '1000 !important'
            },
            expression: "{\n              zIndex: '1000 !important',\n            }"
          }]
        }, 'ul', resultListProps, false), resultListListeners), _vm._l(results, function (result, index) {
          return _c('li', _vm._b({
            directives: [{
              name: "chakra",
              rawName: "v-chakra",
              value: {
                bg: 'white !important',
                p: 0
              },
              expression: "{\n                bg: 'white !important',\n                p: 0,\n              }"
            }],
            key: resultProps[index].id,
            attrs: {
              "d": "flex",
              "align-items": "center",
              "gridGap": "10px",
              "_hover": {
                bg: '#fafafa !important'
              }
            }
          }, 'li', resultProps[index], false), [_c('c-image', {
            attrs: {
              "h": "64px",
              "w": "64px",
              "object-fit": "cover",
              "src": result.photoUrl
            }
          }), _c('c-text', {
            attrs: {
              "flex": "1"
            }
          }, [_vm._v(" " + _vm._s(result.ingredientName) + " ")])], 1);
        }), 0)])];
      }
    }])
  }), !_vm.$v.mealGlossaryId.required ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500",
      "font-size": "12px"
    }
  }, [_vm._v(" Glossary Makanan tidak boleh kosong ")]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }